.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.center-align {
  text-align: center;
}

:root {
  --theme-color: #005780;
  --link-color: #61dafb;
  --body-background-color: #EFF3F6;
  --placeholder-color: #7A7A7A;
  --box-shadow-color: rgba(0, 0, 0, 0.15);
  --button-text-color: #fff;
  --button-border-color: #000;

  --header-background-color: #F5F5F5;
  --app-header-color: #282c34;
  --header-text-color: white;
  --header-widget-color: #005780;
  --header-box-shadow-color: rgba(17, 17, 26, 0.1);

  --sidebar-background-color: #337999;
  --sidebar-text-color: #99BCCC;
  --sidebar-hover-color: #7ba0b1;
  --sidebar-box-shadow-color: grey;
  --sidebar-navigation-color: white;
  --sidebar-sub-navigation-color: #ffffff30;
}

.App-header {
  background-color: var(--app-header-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--link-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  /* background-color: var(--body-background-color); */
  /* background-color: #eb0ba8; */
}

::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeholder-color);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--placeholder-color);
}

.graph-card-boxshadow {
  box-shadow: 0px 2px 4px var(--box-shadow-color);
}

.spark-default-btn {
  padding: 7px 11px;
  border-radius: 6px;
  background-color: var(--theme-color);
  color: var(--button-text-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  border: 1px solid var(--theme-color);
  transition: .5s;
}

.spark-default-btn:hover {
  background-color: var(--button-text-color);
  color: var(--theme-color);
  transition: .5s;
  border: 1px solid var(--theme-color);
}

.spark-default-btn-outline {
  padding: 7px 11px;
  border-radius: 6px;
  background-color: var(--button-text-color);
  color: var(--theme-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid var(--theme-color);
  white-space: nowrap;
  transition: .5s;
}

.spark-default-btn-outline:hover {
  background-color: var(--theme-color);
  color: var(--button-text-color);
  transition: .5s;
}

.mainContentContainer {
  width: calc(100% - 250px);
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.mainContent {
  /* height: calc(100vh - 160px); */
  /* overflow: auto; */
  /* overflow: overlay; */
  width: calc(100vw - 315px);
  overflow-y: hidden;
}

.mainContent h2.mainContentTitle {
  font-weight: 500;
  font-size: 40px;
  line-height: 27px;
}

.tab-content .mainContent {
  height: calc(100vh - 180px);
  overflow: auto;
}

.nav-tabs button:hover {
  transition: .3s;
  color: var(--button-text-color);
  background-color: var(--theme-color) !important;
  border-color: var(--button-border-color);
}

.nav-tabs button.active {
  transition: .3s;
  background-color: var(--button-text-color);
}


/* .sideBarSearchContainer i {
  color: var(--theme-color);
}

.sideBarSearchContainer .input-group {
  margin-right: 16px;
}

.sideBarSearchContainer .btn {
  border-radius: 0;
} */

/* Table  */
.table-dropdown-elliosis::before {
  content: unset !important;
}

/* .spark-default-table {
  margin-bottom: 20px;
}

.spark-default-table td {
  box-shadow: inset 0px -1px 0px #F2F5F7;
}

.spark-default-table tr td,
.spark-default-table tr th {
  padding: 15px 7px;
}

.spark-default-table tr td:first-child,
.spark-default-table tr th:first-child {
  padding: 15px 0 15px 23px;
}

.spark-default-table tr th {
  font-weight: 500;
  font-size: 12px;
  color: #7a7a7a;
  text-transform: uppercase;
}

.spark-default-table span.td-active,
.spark-default-table p.td-active {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  color: var(--theme-color);
  margin: 0;
}

.spark-default-table tr td p {
  margin: 0 !important;
}

.spark-default-table tr td {
  height: 66px;
  vertical-align: middle;
}

.spark-default-table .action-buttons {
  display: inline-flex;
  justify-content: space-between;
  gap: 16px;
}

.spark-default-table .action-buttons i {
  font-size: 16px;
  color: var(--theme-color);
} */

/* .spark-table-controller {
  margin-right: 34px;
  margin-bottom: 24px;
}

.spark-table-controller .tr-count {
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 300;
  margin-right: 24px;
}

.spark-table-controller .tr-count-select {
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 300;
  margin-right: 54px;
}

.spark-table-controller .tr-count-select .dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  border: 0;
  color: #4A4A4A;
  font-size: 12px;
} */

.byRatesFilter.mainContentFilter {
  margin-bottom: 16px;
}

/* .mainContentFilter select,
.mainContentFilter input,
.mainContentFilter .btn {
  height: auto;
  width: 100%;
} */

/* .byRatesFilter.mainContentFilter div#demo-multiple-checkbox {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
} */

/* .byRatesFilter.mainContentFilter input {
  padding: 0.375rem 0.75rem;
} */

/* .byRatesFilter.mainContentFilter .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100%;
} */

.byRatesFilter.mainContentFilter label.MuiInputLabel-formControl {
  position: absolute;
  top: -6px;
  line-height: 1 !important;
}

.byRatesFilter.mainContentFilter label.MuiInputLabel-formControl.MuiInputLabel-shrink {
  top: 0;
}

.serviceAnalysisCostOverTime.byRatesFilter.mainContentFilter label.MuiInputLabel-formControl {
  top: 0;
}

/* .sideBarFilterContentTitle {
  text-transform: uppercase;
  margin: 0;
  font-size: 12px;
  line-height: 18px;
}

/* Filter Section * /
.sideBarFilterContent {
  margin-bottom: 40px;
}

.sideBarFilterContent .filter-select-selection .fiter-badge {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: var(--theme-color);
  border-radius: 6px;
  border: 1px solid var(--theme-color);
  padding: 7px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.sideBarFilterContent .filter-select-selection {
  gap: 7px;
}

.sideBarFilterContent .filter-select-selection .fiter-badge {
  gap: 7px;
}

.sideBarGraphContainer {
  border: 0.953958px solid #EDEDED;
} */

/* .filter-select-selection .fiter-badge .remove-filter {
  margin-left: 10px;
  cursor: pointer;
} */

/* span.filter-item-container {
  gap: 4px;
  padding: 4px;
  background-color: #CCDDE6;
} */

/* .fiter-badge-reset {
  font-size: 12px;
  color: var(--theme-color);
  text-decoration: underline;
  margin-left: 4px;
  cursor: pointer;
  font-weight: 500;
} */

/* Search Result Card  */
/* .search-results-cards-item {
  background-color: #CCDDE6;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 11px 16px 13px 16px;
  width: 100%;
} */

/* .search-results-card-title {
  color: var(--theme-color);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
} */

/* .search-results-card-info {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  color: var(--theme-color);
} */

/* .byRateClassResultsCards {
  justify-content: space-between;
  display: flex;
} */

/* .byRateClassResultsCardsContainer {
  background-color: #CCDDE6;
  margin-bottom: 50px;
} */

/* .backButton {
  font-size: 12px;
  font-weight: 500;
  color: var(--theme-color);
  text-decoration: underline;
  margin-right: 24px;
  cursor: pointer;
} */

/* .byRateClassResults .spark-search-tab-card input,
.byRateClassResults .spark-search-tab-card .btn {
  height: auto;
} */

/* .spark-item-title {
  padding: 16px;
}

.spark-item-title h2 {
  font-weight: 800;
  font-size: 16px;
  color: #0a0a0a;
  line-height: 20px;
} */

/* .spark-default-table-container tr.active-tr {
  background: #ccdde6;
} */

/* @media screen and (max-width:470px) {
  .spark-table-controller {
    flex-direction: column;
    margin: 0;
    padding-left: 24px;
  }
} */

/* @media screen and (min-width:717px) and (max-width:768px) {

  .byRateClassResultsCards {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: unset;
  }

}

@media screen and (max-width:716px) {
  .byRateClassResultsCards {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: unset;
  }
}

@media screen and (max-width:425px) {
  .byRateClassResultsCards {
    grid-template-columns: auto;
  }
} */

.MuiFormControl-root.MuiTextField-root {
  display: block;
}

.rateCalc {
  padding: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Authentication UI Styles */
.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
  text-align: center;
}

.auth-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.sign-in-button {
  background-color: #1976d2;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.sign-in-button:hover {
  background-color: #1565c0;
}